import { Grid, IconButton } from "@mui/material";
import EastIcon from '@mui/icons-material/East';
import WestIcon from '@mui/icons-material/West';
import React from "react";
import '../assets/css/stepstool.scss'

const StepsTool = (props) => {
return <Grid container className="step-control">
    <Grid item className="back" lg={2}
        sx={{ visibility: props.backDisable ? 'hidden' : 'visible' }}
    >
        <IconButton size="large" color="primary"
            disabled={props.backDisable}
            onClick={props.back}
        >
            <WestIcon sx={{ borderRadius: '30%', border: '1px' }} />
        </IconButton>
        <h3>Back</h3>
    </Grid>
    <Grid item className="next" lg={2}
        sx={{ visibility: props.nextDisable ? 'hidden' : 'visible' }}
    >
        <h3>Next</h3>
        <IconButton size="large" color="inherit"
            // disabled={props.activeStep === props.steps.length - 1}
            disabled={props.nextDisable}
            onClick={props.next}
        // onClick={handleNext}
        >
            <EastIcon sx={{ borderRadius: '30%', border: '1px' }} />
        </IconButton>
    </Grid>
        </Grid>
}

export default StepsTool