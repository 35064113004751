import React from "react";
import '../assets/css/needs.scss'
import { Avatar, Box, List, ListItem, ListItemAvatar, ListItemText } from "@mui/material";
import SportsMartialArtsIcon from '@mui/icons-material/SportsMartialArts';
import ComputerIcon from '@mui/icons-material/Computer';
import TranslateIcon from '@mui/icons-material/Translate';
import ForumIcon from '@mui/icons-material/Forum';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import StepsTool from "./StepsTool";

const Needs = (props) => {
  const [subStep, setSubStep] = React.useState('first');
  const next = ()=>{
    if(subStep === 'first') {
      setSubStep('last');
      //document.getElementById('needs_answer').classList.remove("deactive");
    }
    else if(subStep === 'last') props.next()
  }
  return <>
    { !props.display ? <></> : <>  <article className="needs">
      <section>
        <h1>
          <p>What is highly required in your job?</p>
        </h1>
        <h6>
          <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <ComputerIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Any DevOps skills?" />
            </ListItem>
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <PrecisionManufacturingIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Machine Learning?"/>
            </ListItem>
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <SportsMartialArtsIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Martial Arts?" />
            </ListItem>
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <TranslateIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Speaking Chinese Fluently?"/>
            </ListItem>
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <ForumIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Negotiation?" secondary="Talking to customers, ..." />
            </ListItem>
            
          </List>
        </h6>
      </section>
      <Box id="needs_answer" className={subStep=='first'? 'deactive' : 0}>
        <h1>
          <p>I will do it.<br/>
          If I don’t know how, I will learn it.<br/></p>
          <p>But I need to know some things about what you are doing.</p>
        </h1>
      </Box>
    </article>
    <StepsTool next={next} back={props.back} /> </>}
  </>
}

export default Needs