import React from "react";
import '../assets/css/greeting.scss'
import shuttle from '../assets/img/shuttle.png'
import StepsTool from "./StepsTool";

const Greeting = (props) => {
    return <>
        { !props.display ? <></> : <><article className="greeting">
            <section className="hi">
                <h3>Hi, Dear representative of </h3><h2>{props.recruitmenter.name} !</h2>
            </section>
            <section className="quot">
                <h2>
                    <p>“The opportunity is not to discover the perfect company for ourselves.</p>
                    <p>The opportunity is to <u>build</u> the perfect company for <u>each other</u>.”</p>
                </h2>
                <h5>
                    Simon Sinek
                </h5>
            </section>
            <section className="pic">
                <img src={shuttle} alt="shuttle" />
            </section>
            <section className="intro">
                <h5>
                    <p>My name is Mostafa. You can call me Deniz </p>
                    <p>I have been working as a software engineer and developer for 8 years.
                    I have experienced varied circumstances from pretty formal company to my own startup.
                        from C# to python. I am looking for a goal-oriented team or company to work with.</p>
                    <p>I am pleased to have you here. If you need a colleague in software developing field please press the 'Next' button below.</p>
                
                </h5>
            </section>
        </article>
        <StepsTool next={props.next} backDisable={true} /></>}
    </>
}

export default Greeting