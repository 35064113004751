import React from 'react'

// import rebool_rtl from "./assets/css/bootstrap-reboot-rtl.css"
// import reboot from "./assets/css/bootstrap-reboot.css"
// import "./assets/css/bootstrap-rtl.css"
// import "./assets/css/bootstrap.css"
// import "./assets/css/custom.css"
// // import "./assets/css/vote.css"

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

// import botstrp from './assets/js/bootstrap.js'
// import owl from './assets/owlcarousel/owl.carousel.min.js'


const Skyido = () => {
    return <>

    <div className="skyido container">
        <div className="row justify-content-between header">

            <div className="col-3 col-sm-2 col-md-2 col-lg-1 offset-1 offset-md-0">
                <a href="https://skydo.ir"><img src={require('./assets/res/header/logo.png')} width="30"/></a>
            </div>
            <div className="col-5 col-md-9 col-lg-8">
                <nav className="navbar navbar-expand-md navbar-info sticky-top">
                    <button className="navbar-toggler mr-auto" type="button" data-toggle="collapse" data-target="#myNavbar">
                        <img src={require('./assets/res/header/menu.png')} height="30" width="30" />
                    </button>
                    <div className="collapse navbar-collapse" id="myNavbar">
                        <ul className="navbar-nav ml-auto" style={{fontSize: '13px', fontFamily: 'bold'}}>
                            <li className="nav-item">
                                <a href="#intro-video" className="nav-link">ویدیو معرفی</a>
                            </li>
                            <li className="nav-item">
                                <a href="#about-skydo" className="nav-link">درباره <span hidden>اسکاییدو</span>اسکایدو</a>
                            </li>
                            <li className="nav-item">
                                <a href="#app-ui" className="nav-link">نمای اپلیکیشن</a>
                            </li>
                            <li className="nav-item">
                                <a href="#" className="nav-link">حریم خصوصی</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#" >پرسشهای متداول<span className="caret"></span></a>
                            </li>
                            <li className="nav-item">
                                <a href="#about-us" className="nav-link">درباره ما</a>
                            </li>
                            <li className="nav-item">
                                <a href="#contact-us" className="nav-link">تماس با ما</a>
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>
        </div>



        <div className="row mt-5 justify-content-between">
      <div className="col-12 col-md-7 col-lg-6">
          <div className="row">
            <div className="col-12 col-md-6 col-lg-4"> 
                <a href={require('./assets/documents/skydo.apk')}><img className="p-30 w-100" style={{borderRadius: '5px'}} src={require('./assets/res/app/blue-right-download.png')} alt="app"/></a>
            </div>
            <div className="col-12 col-md-6 col-lg-4"> 
                <a href="https://play.google.com/store/apps/details?id=com.skyido"><img className="w-100" style={{borderRadius: '5px'}} src={require('./assets/res/app/play.png')} alt="app"/></a>
            </div>
            <div className="col-12 col-md-6 col-lg-4"> 
                <a href="https://cafebazaar.ir/app/com.skyido"><img className="w-100" style={{borderRadius: '5px'}} src={require('./assets/res/app/bazaar.png')} alt="app"/></a>
            </div>
          </div>
          <br/>
          <br/>
          <h2 className="bold-text">چرا <span hidden>اسکاییدو</span>اسکایدو؟</h2>
          <p className="banner-text">
              ماشین نداری و مجبوری که زودتر از خواب پاشی که زودتر کنار
              <br/>
              خیابون وایسی که زودتر یه ماشین گیرت بیاد که به موقع برسی سر کار <b>؟</b>
              <br/>
              ماشین داری و دلت میخواد که زودتر راه بیفتی که تو مسیر
              <br/>
              روزمره سرکارت چهارتا مسافر بزنی که هم خرج بنزینت در بیاد هم ماشینت خالی نره <b>؟</b>
              <br/>
              اگه مسافری سر ساعت از خواب پا میشی <b>،</b> سر ساعت کنار خیابون وایمیستی
              <br/>
              سر ساعت سوار ماشین میشی و به موقع میرسی <b>!</b>
              <br/>
              اگه راننده ای با خیال راحت راه میفتی <b>،</b> بدون اینکه مدام ترمز بزنی چند تا
              <br/>
              مسافر سوار میکنی هم پول بنزینت درمیاد هم مطمئنی که ماشینت خالی نره <b>!</b>
          </p>

          {/* <!-- <img width="30%" src="assets/res/app/play.png" alt="playstore">
          <a href="https://cafebazaar.ir/app/com.skydo"><img width="26%" src="assets/res/app/bazzar.png" alt="bazzar"></a> --> */}

      </div>
      <div className="col-12 col-md-5 mt-3 mt-md-0">
          <img src={require('./assets/res/header/banner.png')} width="100%"/>
      </div>
  </div>
  <div className="row mt-5 justify-content-center">
      <h1 className="title-style" id="intro-video">ویدیو معرفی</h1>
      <div className="w-100"></div>
      <h1 className="mb-3 break-line-style">
          ---------------</h1>
      <div className="w-100"></div>
      <div className="col-12 col-md-9 mt-5">
          <div className="embed-responsive embed-responsive-21by9">
              <iframe className="embed-responsive-item video-style"
                      src="https://www.aparat.com/video/video/embed/videohash/MPV0z/vt/frame"></iframe>
          </div>
      </div>
  </div>
  <div className="row mt-5 justify-content-center">
      <h1 className="title-style" id="about-skydo">درباره <span hidden>اسکاییدو</span>اسکایدو</h1>
      <div className="w-100"></div>
      <h1 className="break-line-style">
          ---------------</h1>
  </div>
  <div className="row justify-content-between">
      <div className="col-12 col-md-6 align-self-center">
          <h5 className="bold-text">
              اشتراک سفر با کارمندای هم مسیر خودت
              <br/>
              <small className="text-muted about-skydo-text">اگه ماشین داری و کارمندی می تونی در مسیر رفت و برگشت از خونه تا سر کارت، صندلی های خالی ماشینت رو به اشتراک بذاری، مسیر مستقیم خودتو بری، کسب درامد کنی یا با درآمد بدست اومده از اشتراک سفرت، روزهای بعدی رو خودت به عنوان مسافر به سر کارت بری</small>
          </h5>
      </div>
      <div className="col-12 col-md-6 mt-5 text-center">
          <img src={require('./assets/res/about-skydo/carpool.png')} width="60%"/>
      </div>
  </div>
  <div className="row justify-content-between mt-5">
      <div className="col-12 col-md-6 order-last order-md-first mt-5 text-center">
          <img src={require('./assets/res/about-skydo/save-time.png')} width="60%"/>
      </div>
      <div className="col-12 col-md-6 align-self-center order-first order-md-last">
          <h5 className="bold-text">
              صرفه جویی در زمان سفرهای روزمرت
              <br/>
              <small className="text-muted about-skydo-text">مسافری و راهت دوره، تاکسی مستقیم از خونه تا سرکارت نیست، اوتوبوس و مترو شلوغه و باید برای رسیدن به موقع تا سر کارت کلی دردسر داری. با <span hidden>اسکاییدو</span>اسکایدو راننده های هم مسیرتو پیدا کن و در زمانی که در ترافیک و مسیرهای پرت می گذرونی صرفه جویی کن</small>
          </h5>
      </div>
  </div>
  <div className="row justify-content-between mt-5">
      <div className="col-12 col-md-6 align-self-center">
          <h5 className="bold-text">
              صرفه جویی در هزینه های سفرهای روزمرت
              <br/>
              <small className="text-muted about-skydo-text">راننده ای و فقط می خوای مسیر مستقیم تا سر کارت بری و بعد از ساعات کاری مسیر مستقیمی به خونه داشته باشی، دیگه نمی خواد مدام ترمز کنی و مسافرا رو پیاده و سوار کنی، <span hidden>اسکاییدو</span>اسکایدو اینجاست تا به جای تو برات بوق بزنه و برات تو مسیر خودت مسافر پیدا کنه و توام ازش کسب درآمد کنی 
                  حالا مسافری و هر روز از خودرو های بین راهی و تاکسی ها ایستگاهی استفاده می کنی ولی هم راهت دور میشه هم کلی هزینه اضافی هم می دی. <span hidden>اسکاییدو</span>اسکایدو اینجاست تا بهترین مسیرها و راننده های هم مسیرتو بهت با بهترین قیمت نشون بده</small>
          </h5>
      </div>
      <div className="col-12 col-md-6 mt-5 text-center">
          <img src={require('./assets/res/about-skydo/save-money.png')} width="60%"/>
      </div>
  </div>
  <div className="row mt-5 justify-content-center">
      <h1 className="title-style" id="app-ui">نمای اپلیکیشن</h1>
      <div className="w-100"></div>
      <h1 className="break-line-style">
          ---------------</h1>
  </div>
  <OwlCarousel className="owl-carousel owl-theme mt-4">
      <div className="item"><img src={require('./assets/res/ui/1.jpg')}/></div>
      <div className="item"><img src={require('./assets/res/ui/2.jpg')}/></div>
      <div className="item"><img src={require('./assets/res/ui/3.jpg')}/></div>
      <div className="item"><img src={require('./assets/res/ui/5.jpg')}/></div>
      <div className="item"><img src={require('./assets/res/ui/6.jpg')}/></div>
  </OwlCarousel>

  <div className="row mt-5 justify-content-center">
      <h1 className="title-style" id="about-us">درباره ما</h1>
      <div className="w-100"></div>
      <h1 className="break-line-style">
          ---------------</h1>
  </div>
  <div className="row mt-3">
      <div className="col-12 col-md-6">
          <h5 className="text-muted about-us-text">
              در سال 1398 تیم <span hidden>اسکاییدو</span>اسکایدو با هدف کاهش ترافیک و آلودگی هوا و افزایش اثر بخشی حمل و نقل ، متناسب با نیاز کارمندان و دانش آموزان ایجاد شد . چشم انداز تیم <span hidden>اسکاییدو</span>اسکایدو کاهش خودرو های تک سرنشین از چرخه صنعت حمل و نقل روزانه می باشد
          </h5>
      </div>
      <div className="col-12 col-md-6 text-center mt-3 mt-md-0 about-us-banner-container">
          <h1 className="about-us-banner-title">skydo</h1>
      </div>
  </div>


        <div className="row mt-5 justify-content-center">
            <h1 className="title-style" id="contact-us">تماس با ما</h1>
            <div className="w-100"></div>
            <h1 className="break-line-style">
                ---------------</h1>
        </div>
        <div className="row justify-content-between">
            <div className="col-12 col-md-5 align-self-center contact-us-container">
                <h4 className="contact-us-subject">
                    شماره تلفن : <span
                        style={{fontFamily: 'pernum'}}>28427348 - 021</span></h4>
                <h4 className="contact-us-subject">ایمیل :
                    admin@skydio.com </h4>
                <h4 style={{fontSize: 'large', fontFamily: 'bold', color: 'white', lineHeight: '2.5rem'}}>
                    <span
                        style={{fontFamily: 'bold'}}>آدرس : </span>تهـــران ،
                    میدان آزادی،‌ ابتدای بزرگراه شهید لشکری، نبش ایستگاه مترو بیمه،‌ کارخانه نوآوری آزادی</h4>
                    <div className="row">
                        <div className="col-12 col-md-6 col-lg-4">
                            <a href={require('./assets/documents/skydo.apk')}><img className="p-30 w-100" style={{borderRadius: '5px'}} src={require('./assets/res/app/white-right-download.png')} alt="app"/></a>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4">
                            <a href="https://play.google.com/store/apps/details?id=com.skyido"><img className="w-100" style={{borderRadius: '5px'}} src={require('./assets/res/app/play.png')} alt="app"/></a>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4">
                            <a href="https://cafebazaar.ir/app/com.skyido"><img className="w-100" style={{borderRadius: '5px'}} src={require('./assets/res/app/bazaar.png')} alt="app"/></a>
                        </div>
                    </div>
                    <br/>
                        <a href="https://instagram.com/skydo.team"><img className="mr-2" width="6%" src={require("./assets/contact-us/instagram.png")} alt="instagram"/></a>
                        <a href="https://www.linkedin.com/company/skyido"><img className="mr-2" width="5.5%" src={require("./assets/contact-us/linkedin.png")} alt="linkedin"/></a>
                        <a href="https://t.me/skyido"><img className="mr-2" width="6%" src={require("./assets/contact-us/telegram.png")} alt="telegram"/></a>
                    </div>
                    <div className="col-12 col-md-6 text-center mt-3 mt-md-0">
                        <img width="100%" src={require("./assets/contact-us/contact-us.png")} alt="contact us"/>
                    </div>
            </div>
        </div>
        
        <div className="container-fluid">
            <div className="row">
                <div style={{orderRadius: '10px'}} className="col bg-dark">
                    <h6 className="mt-2" style={{fontFamily: 'reqular', color: 'white', padding: '5px'}}> تمامی حقوق مادی و معنوی این وب سایت
                        متعلق به تیم <b><span hidden>اسکاییدو</span>اسکایدو</b> می باشد. </h6>
                </div>
            </div>
        </div></>
}

export default Skyido