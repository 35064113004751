import React from "react";
import '../assets/css/words.scss'
import StepsTool from "./StepsTool";
import { Button } from "@mui/material";

const Words = (props) => {
    return <>
       { !props.display? <></> : <><article className="words">
       <p>As you know, there are so many firms with varied needs and requirements as well as new technologies that change day by day.
       Therefor developers have to stay up-to-date. But learning each technology is not efficent at all.That is why I rather find enthusiastic team with clear duty of their firm then learn ( if I don't know ) and apply what they need and stay beside them, than learn any unnecessery things in order to struggling to find a job.
       </p>
       <p>I beleive there are some simple "Hello, World..." pages that make sence and on the other hand there are some huge platforms that are pretty meaningless (at least in my vision).
        And working for or with each of their owners can make you passionate in a different way.</p>
        <p>I created this page to assess how we can impress each other in order to make a correct decision in our work path.</p>
       <br/>
       Now, I invite you to take a look at my resume. Thank you for spending your time to check that out. 
    <br/><Button variant="outlined" href="#outlined-buttons">
  <a href={props.recruitmenter.resume} target="_blank">Download Resume</a>
</Button> 
        </article>
        
    <StepsTool back={props.back} next={props.next} /></>}
    </>
}

export default Words