import React from "react";
import '../assets/css/techs.scss'
import StepsTool from "./StepsTool";
import {Container, ImageList, ImageListItem, ImageListItemBar } from "@mui/material";
import jsc from '../assets/img/js.png'
import rec from '../assets/img/react.png'
import pyt from '../assets/img/python.png'
import mui from '../assets/img/mui.png'
import formi from '../assets/img/formik.png'
import aws from '../assets/img/aws.jpg'
import cloudfront from '../assets/img/cloudfront.png'
import s3 from '../assets/img/s3.webp'
import scss from '../assets/img/scss.png'
import gw from '../assets/img/api_gw.png'
import lambda from '../assets/img/lambda.png'
import route53 from '../assets/img/route53.png'
import cloudwatch from '../assets/img/cloudwatch.webp'
import chatra from '../assets/img/chatra.png'
import adobe from '../assets/img/adobecolor.png'
import dynamodb from '../assets/img/dynamodb.png'

const Techs = (props) => {
    const itemData = [
        {img:jsc, title:"javascript"},
        {img:rec, title:"react.js"},
        {img:pyt, title:"python"},
        {img:mui, title:"MUI"},
        {img:formi, title:"formik"},
        {img:scss, title:"scss"},
        {img:cloudfront, title:"aws cloudfront"},
        {img:aws, title:"aws"},
        {img:gw, title:"aws api gateway"},
        {img:lambda, title:"aws lambda"},
        {img:dynamodb, title:"aws dynamodb"},
        {img:s3, title:"aws s3"},
        {img:route53, title:"aws route 53"},
        {img:cloudwatch, title:"aws cloudwatch"},
        {img:chatra, title:"chatra"},
        {img:adobe, title:"adobe color"},
    ]
    return <>
        {!props.display ? <></> : <><article className="techs">
        Before I say what I need, it is good to mention that these tools, languages and infrastructures helped me to talk to you. Thanks them ...
            <Container maxWidth='sm'><ImageList variant="masonry" cols={4} gap={8}>
                {itemData.map((item) => (
                    <ImageListItem key={item.title}>
                        <img
                            src={`${item.img}?w=164&fit=crop&auto=format`}
                            srcSet={`${item.img}?w=164&fit=crop&auto=format&dpr=2 2x`}
                            alt={item.title}
                            loading="lazy"
                        />
                        <ImageListItemBar title={item.title} />
                    </ImageListItem>
                ))}
            </ImageList></Container>
        </article>
            <StepsTool back={props.back} next={props.next} /></>}
    </>
}

export default Techs