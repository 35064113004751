import React from "react";

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';

import './assets/css/home.scss'
import wd from './assets/img/markus-spiske.jpg'
import ux from './assets/img/ux.jpg'
import ed from './assets/img/alvaro-reyes.jpg'
import srv from './assets/img/server.jpg'
import pl from './assets/img/post-letter.jpg'
import { Avatar, Box, Button, CardActionArea, CardHeader, Chip, Container, Grid, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, Paper, Stack } from "@mui/material";
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import PaymentIcon from '@mui/icons-material/Payment';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import mypic from './assets/img/me.jpg'
import FadeInSection from "./components/FadeInSection";

import { useIntl, FormattedMessage } from 'react-intl';

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import { gsap } from "gsap";
// import { ScrollTrigger } from "gsap/ScrollTrigger";
// gsap.registerPlugin(ScrollTrigger);


const Home = (props) => {
    // return <div id="home">
    // <h1>Our site is getting a little tune up and some love.</h1>
    // <div>
    // <p>We apologize for the inconvenience, but we're performing some maintenance. You can still contact us at <a href="mailto:deniz@deniz-biz.com">deniz@deniz-biz.com</a>. We'll be back up soon!</p>
    // <p>— Coding is Love</p>
    // </div>
    // </div>
    // const greating_text = React.useRef();
    // const [animated, setAnimated] = React.useState(false)
    // var intl = require('intl');
    const { formatMessage } = useIntl();
    const grTxt =formatMessage({ id: 'home.gr_txt' });


    const comp = React.useRef();
    function showGreatingText(e,txt, cb){
        if (e) {
            if (txt=='') {
                cb();
                return;
            }
            setTimeout(() => {
                e.textContent += txt[0] == ' '? '\u00a0' : txt[0];
                showGreatingText(e,txt.substring(1,txt.length), cb)
            }, 70)
        }
    }
    React.useLayoutEffect(() => {
        let ctx = gsap.context(() => {
            const t1 = gsap.timeline({
                onComplete: () => {
                    showGreatingText(document.getElementById('greating-text'), grTxt, ()=>{
                        const t2 = gsap.timeline()
                        t2.to('.animate-in', {
                            x: "500",
                            stagger: 0.3
                        }, '>')
                    });
                }
            });
            t1.to('#home2 header .avatar', { width: 56, height: 56, top: '5%', duration: 2 });

        }, comp)
        return()=>{
            ctx.revert();
        }
    }, []);

    const [windowSize, setWindowSize] = React.useState([
        window.innerWidth,
        window.innerHeight]);
    React.useEffect(() => {
        const handleWindowResize = () => {
            setWindowSize([window.innerWidth, window.innerHeight]);
        };

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);





    return <div id="home2" ref={comp}>
        <header>
            <Container>
            </Container>
            <Container>
                <Box><EmailIcon fontSize="small"/> <a href="mailto:deniz@deniz-biz.com">Deniz@deniz-biz.com </a><PhoneIphoneIcon fontSize="small"/> <a href="tel:+905343232400">+905343232400</a></Box>
                <Grid container columns={12}>
                    <Grid item className="avatar-container">
                        <ListItem sx={{paddingLeft:0}} >
                            <ListItemAvatar>
                                <Avatar className="avatar" alt="me" src={mypic} />
                            </ListItemAvatar>
                            <ListItemText primary='Deniz' secondary="Full Stack Developer" />
                        </ListItem>
                    </Grid>
                </Grid>
            </Container>
        </header>
        <section id="greating">
            <Box className="bg"></Box>
            <Box className="content">
                <Box className="text">
                    <h2>
                        <FormattedMessage id="home.greating"/>
                    </h2>
                    <h3 id='greating-text'></h3>
                </Box>
                <List className="category">
                    <ListItemButton className="animate-in" component="a" href="#services">
                            <ListItemAvatar>
                                <Avatar><HomeRepairServiceIcon /></Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={formatMessage({ id: 'home.srv_prd' })} secondary={formatMessage({ id: 'home.srv_prd_tag' })} />
                    </ListItemButton>
                    <ListItemButton className="animate-in" component="a" href="#portfolio">
                        <ListItemAvatar>
                            <Avatar><PrecisionManufacturingIcon /></Avatar>
                        </ListItemAvatar>
                            <ListItemText primary={formatMessage({ id: 'home.portfolio' })} secondary={formatMessage({ id: 'home.portfolio_tag' })} />
                    </ListItemButton>
                    <ListItemButton className="animate-in" component="a" href="#music">
                        <ListItemAvatar>
                            <Avatar><MusicNoteIcon /></Avatar>
                        </ListItemAvatar>
                            <ListItemText primary={formatMessage({ id: 'home.musics' })} secondary={formatMessage({ id: 'home.musics_tag' })} />
                    </ListItemButton>
                </List>
            </Box>
        </section>
        <section id="services">
            <Container><h1>
                <Paper>
                        <FormattedMessage id="home.srvs"/>
                </Paper></h1>
            </Container>
            <Container>
                <FadeInSection>
                <OwlCarousel className='owl-theme' items={windowSize[0] < 800 ? 1 : windowSize[0] < 1200? 2 : 3} autoplay={windowSize[0] < 1200? true : false} loop margin={10}>
                    <div className="item">
                    <Card>
                            <CardActionArea>
                            <CardMedia component='img' height='150' image={wd} />
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="div">
                                        <FormattedMessage id="home.web_dev" />
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                        <FormattedMessage id="home.web_dev_des" />
                                </Typography>
                                    <Stack direction="row" spacing={1}>
                                        <Chip label="React" color="success" size="small"/>
                                        <Chip label="Django" color="primary" size="small" />
                                    </Stack>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                    </div>
                    <div className="item">
                        <Card>
                        <CardActionArea>
                            <CardMedia component='img' height='150' image={ed} />
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="div">
                                        <FormattedMessage id="home.erp_imp" />
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                        <FormattedMessage id="home.erp_imp_des" />
                                </Typography>
                                <Stack direction="row" spacing={1}>
                                        <Chip label="ERP" color="success" size="small"/>
                                        <Chip label="Odoo" color="primary"  size="small"/>
                                    </Stack>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                    </div>
                    <div className="item">
                            <Card>
                                <CardActionArea>
                                    <CardMedia component='img' height='150' image={ux} />
                                    <CardContent>
                                        <Typography gutterBottom variant="h5" component="div">
                                            <FormattedMessage id="home.mobile_dev" />
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            <FormattedMessage id="home.mobile_dev_des" />
                                        </Typography>
                                        <Stack direction="row" spacing={1}>
                                            <Chip label="React Native" color="success" size="small" />
                                            <Chip label="Java" color="primary" size="small" />
                                        </Stack>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                    </div>
                </OwlCarousel>
                </FadeInSection>
            </Container>
            <Container>
                <h1>
                    <Paper>
                        <FormattedMessage id="home.prds" />
                    </Paper>
                </h1>
            </Container>
            <Container>

                <FadeInSection>
                    <OwlCarousel className='owl-theme' items={windowSize[0] < 800 ? 1 : 2} autoplay={windowSize[0] < 800? true : false} loop margin={10}>
                        <div className="item">

                            <Card>
                                <CardActionArea>
                                    <CardMedia component='img' height='150' image={srv} />
                                    <CardContent>
                                        <Typography gutterBottom variant="h5" component="div">
                                            <FormattedMessage id="home.web_hosting" />
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            <FormattedMessage id="home.web_hosting_des" />
                                        </Typography>
                                        <Stack direction="row" spacing={1}>
                                            <Chip label="AWS" color="success" size="small" />
                                            <Chip label="Heroku" color="primary" size="small" />
                                        </Stack>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </div>
                        <div className="item">
                            <Card>
                                <CardActionArea>
                                    <CardMedia component='img' height='150' image={pl} />
                                    <CardContent>
                                        <Typography gutterBottom variant="h5" component="div">
                                            <FormattedMessage id="home.mail_server" />
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            <FormattedMessage id="home.mail_server_des" />
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </div>
                    </OwlCarousel>
                </FadeInSection>
            </Container>
        </section>
        <section id="portfolio">
            <Container>
                <h1><Paper>
                    <FormattedMessage id="home.portfolio" />
                </Paper></h1>
            </Container>
            <Container>
                <OwlCarousel className='owl-theme' items={windowSize[0]<800 ? 1 : 2} loop margin={10} autoplay={true}>
                    <div className="item">
                            <Card>
                                <CardActionArea>
                                    <CardHeader

                                    avatar={
                                        <Avatar className="avatar" alt="me" src={require('./assets/img/logo/petit.png')} />
                                      }
                                    title="Petit Monde Group" subheader="Web Site" />
                                    <CardMedia>
                                        <CardMedia component='img' height='150' image={require('./assets/img/coms/petit.jpg')} />
                                    </CardMedia>
                                    <CardContent>
                                        <Typography gutterBottom variant="h5" component="div">
                                        <FormattedMessage id="home.petit_title" />
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                        <FormattedMessage id="home.petit_des" />
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            <CardActions>
                                <Button size="small" color="primary" href="http://www.petitmondegroup.com/">
                                        <FormattedMessage id="home.open_site" />
                                </Button>
                            </CardActions>
                            </Card>
                    </div>
                    <div className="item">
                            <Card>
                                <CardActionArea>
                                    <CardHeader 
                                    avatar={
                                        <Avatar className="avatar" alt="me" src={require('./assets/img/logo/skyido.png')} />
                                      }
                                    title="Skyido" subheader="Android Application" />
                                    <CardMedia>
                                        <CardMedia component='img' height='150' image={require('./assets/img/coms/skyido.png')} />
                                    </CardMedia>
                                    <CardContent>
                                        <Typography gutterBottom variant="h5" component="div">
                                        <FormattedMessage id="home.skyido_title" />
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                        <FormattedMessage id="home.skyido_des" />
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            <CardActions>
                                <Button size="small" color="primary" href="https://play.google.com/store/apps/details?id=com.skyido">
                                        <FormattedMessage id="home.open_site" />
                                </Button>
                            </CardActions>
                            </Card>
                    </div>
                    <div className="item">
                            <Card>
                                <CardActionArea>
                                    <CardHeader
                                    avatar={
                                        <Avatar className="avatar" alt="me" src={require('./assets/img/logo/imcc.jpg')} />
                                      }
                                     title="Iran Mall(IMCC)" subheader="Odoo ERP" />
                                    <CardMedia>
                                        <CardMedia component='img' height='150' image={require('./assets/img/coms/imcc.jpg')} />
                                    </CardMedia>
                                    <CardContent>
                                        <Typography gutterBottom variant="h5" component="div">
                                        <FormattedMessage id="home.imcc_title" />
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                        <FormattedMessage id="home.imcc_des" />
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            <CardActions>
                                <Button size="small" color="primary" href="https://www.iranmall.com/en">
                                        <FormattedMessage id="home.open_site" />
                                </Button>
                            </CardActions>
                            </Card>
                    </div>
                    
                </OwlCarousel>
            </Container>
            <Container>
                <h1><Paper>
                    <FormattedMessage id="home.techs" />
                    </Paper></h1>
            </Container>
            <Container>
                <div className="techs">
                    <img src={require('./assets/img/techs/python.png')}/>
                    <img src={require('./assets/img/techs/js.png')}/>
                    <img src={require('./assets/img/techs/django.png')}/>
                    <img src={require('./assets/img/techs/react.png')}/>
                    <img src={require('./assets/img/techs/aws.png')}/>
                    <img src={require('./assets/img/techs/lambda.png')}/>
                    <img src={require('./assets/img/techs/mui.png')}/>
                    <img src={require('./assets/img/techs/s3.webp')}/>
                    <img src={require('./assets/img/techs/api_gw.png')}/>
                </div>
            </Container>
        </section>
<section id="music">
    <Container>
        <Paper>
            <iframe width="100%" height="300" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/114678480&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"></iframe>
        </Paper>
        <Paper>
            <iframe width="100%" height="300" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/285347612&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"></iframe>
        </Paper>
    </Container>
</section>
    </div>
}

export default Home