import './App.scss';
import {Routes, Route} from 'react-router-dom'
import { Home, Recruitmenter, Skyido} from './pages'

function App() {
  return (
    // <ChakraProvider>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/recruitmenter/:id" element={<Recruitmenter />} />
        <Route path="/skyido" element={<Skyido />} />
      </Routes>
    // </ChakraProvider>
  );
}

export default App;
