import React, { Fragment } from "react";
import { Alert, Box, Button, CircularProgress, Container, LinearProgress, MobileStepper, Paper, TextField, Typography, buttonClasses } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import '../assets/css/questions.scss'
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import { useFormik } from "formik";
import * as Yup from 'yup';
import { styled } from '@mui/system';
import StepsTool from "./StepsTool";
// import {
//     FormControl,
//     FormErrorMessage,
//     FormLabel,
//     Heading,
//     Input,
//     Select,
//     Textarea,
//     VStack,
//     theme
//   } from "@chakra-ui/react";

const questions = [
    {
        field: 'customer',
        label: <>Who are your customers? <br /> Their feelings are important to me.</>,
        placeholder: false
    },
    {
        field: 'company',
        label: <>
            What is your company's duty? <br />
            Does it inspires at least yourselves?<br />
            How do you feel about your company? </>,
        placeholder: false
    },
    {
        field: 'offer',
        label: 'How whould working for or with you makes your staff happy?',
        placeholder: 'financially, mentally, physically'
    },
    {
        field: 'wealth',
        label: <> Do you have any plan for "Free Float" ? If yes, How?</>
    },
];

const fields = ['customer', 'company', 'offer', 'wealth']
const Questions = (props) => {
    const [subState, setSubState] = React.useState('')
    ////////////

    const blue = {
        500: '#007FFF',
        600: '#0072E5',
        700: '#0059B2',
    };

    const CustomButton = styled(Button)`
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 0.875rem;
    line-height: 1.5;
    background-color: ${blue[500]};
    color: white;
    border-radius: 8px;
    font-weight: 600;
    padding: 8px 16px;
    cursor: pointer;
    transition: all 150ms ease;
    border: none;
  
    &:hover:not(:disabled) {
      background-color: ${blue[600]};
    }
  
    &:active:not(:disabled) {
      background-color: ${blue[700]};
    }
  
    &.${buttonClasses.focusVisible} {
      box-shadow: 0 4px 20px 0 rgb(61 71 82 / 0.1), 0 0 0 5px rgb(0 127 255 / 0.5);
      outline: none;
    }
  
    &.${buttonClasses.disabled} {
      opacity: 0.5;
      cursor: not-allowed;
    }
  `;
    ///////////
    // const handleSubmit = async (values, { setSubmitting }) => {
    const handleSubmit = (values) => {
        setSubState('submitting')
        const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json', },
            body:JSON.stringify(values) //values
        };
        fetch(`https://fhcwr6v4lg.execute-api.eu-central-1.amazonaws.com/prod/recruitmenters/${props.id}`, requestOptions)
            .then(async response => {
                const isJson = response.headers.get('content-type')?.includes('application/json');
                const data = isJson && await response.json();
    
                // check for error response
                if (!response.ok) {
                    // get error message from body or default to response status
                    const error = (data && data.message) || response.status;
                    return Promise.reject(error);
                }
    
                setSubState('submited')
            })
            .catch(error => {
                console.error('There was an error!', error);
                setSubState('')
            });
        // console.log(`******* ${props.id}`)
        // try {
        //   const response = await axios.post(`https://fhcwr6v4lg.execute-api.eu-central-1.amazonaws.com/prod/recruitmenters/${props.id}`, 
        //   values, {
        //     headers: {'Access-Control-Allow-Origin':"*", 'Content-Type':'multipart/form-data'}
        //   });
        //   setSubState('submited')
        // } catch (error) {
        //   console.error('Error submitting form:', error);
        // }
        // setSubmitting(false);
      };
    const formik = useFormik({
        initialValues: { customer: '', company: '', offer: '', wealth: '' },
        onSubmit: handleSubmit,
        validationSchema: Yup.object().shape({
            customer: Yup.string().required('Required!'),
            company: Yup.string().required('Required!'),
            offer: Yup.string().required('Required!'),
            wealth: Yup.string().required('Required!'),

        })
    });

    const theme = useTheme();
    const [activeStep, setActiveStep] = React.useState(0);
    const maxSteps = questions.length;

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };


    return <>
    { !props.display ? <></> :<><article className="questions">
        <Alert severity="warning">
            <h5>I will spend my attention, energy, enthusiasm and time on your project and company. So, please answer the questions carefully.</h5>
        </Alert>
        <br />
        <Paper elevation={12}>
            <Box>

                <form onSubmit={formik.handleSubmit} data-testid='form'>
                    {questions.map((item, index) =>
                        <React.Fragment key={index}>
                            <Paper
                                square
                                elevation={0}
                                sx={{
                                    display: activeStep == index ? 'flex' : 'none',
                                    alignItems: 'center',

                                    pl: 2,
                                    bgcolor: 'background.default',
                                }}
                            >
                                <label htmlFor={questions[activeStep].field}>{questions[activeStep].label}</label>
                            </Paper>
                            <Container sx={{ display: activeStep == index ? 'inherit' : 'none', height: 255, maxWidth: '100%', width: '100%', p: 2 }}>
                                <TextField
                                    placeholder={questions[activeStep].placeholder}
                                    fullWidth
                                    focused='true'
                                    multiline
                                    rows={4}
                                    // {...<>error</>}
                                    error={formik.errors[questions[activeStep].field]}
                                    label={formik.errors[questions[activeStep].field]}
                                    {...formik.getFieldProps(questions[activeStep].field)}
                                />

                            </Container>
                        </React.Fragment>)
                    }
                    {subState == 'submitting' ? <LinearProgress color="success" sx={{margin:'16px'}} /> : <></>}
                    {subState != 'submited' ? 
                    <CustomButton sx={{ visibility: activeStep != maxSteps - 1 ? 'hidden' : 'visible' , disabled: subState == 'submitting' }} type="submit">Submit</CustomButton>
                    :
                    <h3>Thank you for your answers</h3>}
                </form>
                <MobileStepper variant="text"
                    steps={maxSteps}
                    position="static"
                    activeStep={activeStep}
                    nextButton={
                        <Button size="small" onClick={handleNext} disabled={activeStep === (maxSteps - 1) || !formik.values[questions[activeStep].field]}>
                            Next
                            <KeyboardArrowRight />
                        </Button>
                    }
                    backButton={
                        <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                            <KeyboardArrowLeft />
                            Back
                        </Button>
                    }
                />
            </Box>
        </Paper>
    </article>
    {subState == 'submited' ?<Container maxWidth='sm'>
        <Alert severity="success">
        I appreciate your attention.<br/>
        You can leave a message for me using the chat button below the page or sending an email to ' Baghery00@gmail.com '<br/>
        Thanks...
            </Alert>
        </Container> : <></>}
    <StepsTool next={props.next} back={props.back} nextDisable={true}/> </>}
    </>
}

export default Questions