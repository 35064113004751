import React from "react";
import Greeting from "./components/Greeting";
import Needs from "./components/Needs";
import Questions from "./components/Questions";
import { Box, Step, Stepper, StepButton, Avatar, Grid, Container, IconButton, Alert, Card } from "@mui/material";
import './assets/css/index.scss'
import mypic from './assets/img/me.jpg'
import Words from "./components/Words";
import { useParams } from 'react-router-dom';
import Techs from "./components/Techs";


const Recruitmenter = () => {
    let params = useParams();
    const [windowSize, setWindowSize] = React.useState([
        window.innerWidth,
        window.innerHeight]);
    React.useEffect(() => {
        const handleWindowResize = () => {
            setWindowSize([window.innerWidth, window.innerHeight]);
        };

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);
    const [recruitmenter, setRecruitmenter] = React.useState({})
    const fetchData = () => {
        fetch(`https://fhcwr6v4lg.execute-api.eu-central-1.amazonaws.com/prod/recruitmenters/${params.id}`)
            .then(response => response.json())
            .then(data => 
                {
                    setRecruitmenter(data)
                }
                )
            .then(data => console.log(data))
            .catch(error => alert('invalid link'))
    }

    const [activeSubStep, setActiveSubStep] = React.useState(0);
    const [activeStep, setActiveStep] = React.useState(0);
    const [completed, setCompleted] = React.useState({});
    const handleStep = (step) => () => {
        setActiveStep(step);
      };
    const handleNextSub = () => {
        setActiveSubStep((prevActiveSubStep) => prevActiveSubStep + 1);
    };
    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setActiveSubStep(0)
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };


    React.useEffect(() => {
        fetchData()
      }, [])

const steps = ['Greating', 'A word with you (and download the Resume)', 'What You need', 'What this site has been built with', 'What I need'];
    return ( <>{ windowSize[0]<700? 
    <Container maxWidth='sm' sx={{margin:'16px'}}>
        <Alert severity="info">
        Hi. Thank you for visiting this website.<br/>
        Please open this page on a device with desktop-size screen.<br/>
        I am working on it's responsiveness
            </Alert>
        </Container>
    :<>{Object.keys(recruitmenter).length<1? <div>Please wait...</div>:<Container maxWidth="xl" className="recruitmenter">
        <Grid container columns={12} className="header">
            <Grid container item className='steps' lg={7}>
                <Stepper nonLinear activeStep={activeStep} alternativeLabel>
                    {steps.map((label, index) => (
                        <Step key={label} completed={completed[index]}>
                            <StepButton color="inherit">
                                {label}
                            </StepButton>
                        </Step>
                    ))}
                </Stepper>
            </Grid>
            <Grid item className="avatars" lg={5}>
                <Avatar alt="Me" src={mypic}/>
                <Avatar alt="Me" src={recruitmenter.pic}/>
            </Grid>
        </Grid>
        <Grid container className='body'>
            <Grid item lg={12}>
            
                <Greeting  display={activeStep==0} back={handleBack} next={handleNext} recruitmenter={recruitmenter}/>
                <Words     display={activeStep==1} back={handleBack} next={handleNext} recruitmenter={recruitmenter}  />
                <Needs     display={activeStep==2} back={handleBack} next={handleNext}/>
                <Techs     display={activeStep==3} back={handleBack} next={handleNext}  />
                <Questions display={activeStep==4} back={handleBack} next={handleNext}  id={params.id}   />
                
            </Grid>
        </Grid>
        
        <Grid container className="footer" >
            <div>© Deniz-Biz.com<br/><br/>
            &nbsp;&nbsp;&nbsp;&nbsp;mvp v1.0.3</div>
            <div> Where there is a WILL, there is a WAY</div>
        </Grid>
        </Container>}</>}</>
    )
};

export default Recruitmenter;